import React, { ReactNode } from 'react';
import cn from 'clsx';

import styles from './Input.module.scss';

export interface InputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'size' | 'label'> {
  label?: string | ReactNode;
  variant?: 'primary' | 'secondary';
  isValid?: boolean;
  size?: 'medium';
  inputClassName?: string;
}

const sizeClasses = {
  medium: styles.sizeMedium,
};

const variants = {
  primary: styles.primary,
  secondary: styles.secondary,
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      className,
      isValid,
      variant = 'primary',
      size = 'medium',
      ...props
    },
    externalRef,
  ) => {
    const classNames = cn(
      styles.root,
      variants[variant],
      sizeClasses[size],
      {
        [styles.disabled]: props.disabled,
        [styles.isValid]: isValid,
      },
      className,
    );

    return (
      <label className={classNames}>
        {label && <span className={styles.label}>{label}</span>}
        <input ref={externalRef} className={styles.input} {...props} />
      </label>
    );
  },
);
