/* eslint-disable no-magic-numbers */
// eslint-disable-next-line arrow-body-style
const env = (key: string): string => {
  // eslint-disable-next-line no-underscore-dangle
  return (window.__APP_CONFIG__[key] || process.env[key]) as string;
};

export type AppConfig = {
  env: string;
  apiUrl: string;
  nftContractAddress: `0x${string}`;
  mailchimpAccount: string;
  mailchimpUserId: string;
  mailchimpAudienceId: string;
  mailchimpZoneId: string;
  tokenId: number;
  chainId: number;
  tokenPrice: number;
};

export const appConfig: AppConfig = {
  env: env('NODE_ENV'),
  apiUrl: env('REACT_APP_API_URL'),
  nftContractAddress: env('REACT_APP_NFT_CONTRACT') as `0x${string}`,
  mailchimpUserId: env('REACT_APP_MAILCHIMP_USER_ID'),
  mailchimpAudienceId: env('REACT_APP_MAILCHIMP_AUDIENCE_ID'),
  mailchimpZoneId: env('REACT_APP_MAILCHIMP_ZONE_ID'),
  mailchimpAccount: env('REACT_APP_MAILCHIMP_ACCOUNT'),
  tokenId: env('REACT_APP_TOKEN_ID')
    ? parseInt(env('REACT_APP_TOKEN_ID'), 10)
    : 0,
  tokenPrice: parseFloat(env('REACT_APP_TOKEN_PRICE')) || 0.05,
  chainId: parseInt(env('REACT_APP_CHAIN_ID'), 10) || 5,
};
