import React, { useRef, useEffect } from 'react';

import styles from './Dialog.module.scss';

export interface DialogProps {
  title: string;
  isOpen: boolean;
  content: React.ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

export const Dialog: React.FC<DialogProps> = ({
  title,
  content,
  isOpen,
  onOk,
  onCancel,
  onClose,
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  const handleOk = () => {
    dialogRef.current?.close();
    onOk?.();
  };

  const handleCancel = () => {
    dialogRef.current?.close();
    onCancel?.();
  };

  const handleClose = () => {
    dialogRef.current?.close();
    onClose?.();
  };

  return (
    <dialog className={styles.root} ref={dialogRef}>
      <button
        value="cancel"
        formMethod="dialog"
        className={styles.close}
        onClick={handleClose}
      >
        X
      </button>
      {title && <h4 className={styles.title}>{title}</h4>}
      <div className={styles.content}>{content}</div>
      <div className={styles.btnGroup}>
        {!!onOk && (
          <button value="default" onClick={handleOk}>
            Ok
          </button>
        )}
        {!!onCancel && (
          <button value="cancel" formMethod="dialog" onClick={handleCancel}>
            Cancel
          </button>
        )}
      </div>
    </dialog>
  );
};
