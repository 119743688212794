import React from 'react';
import styles from './Footer.module.scss';

export const Footer: React.FC = () => (
  <footer className={styles.root}>
    <small className={styles.copyright}>
      &copy; 2023 Ferragosto. All Rights Reserved.
    </small>
  </footer>
);
