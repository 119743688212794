import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { WagmiConfig } from 'wagmi';
import { wagmiConfig } from 'config/wagmi';

import { Layout } from 'components/Layout';
import { Home } from 'pages/Home';
import { Mint } from 'pages/Mint';
import { Admin } from 'pages/Admin';

export const App: React.FC = () => (
  <WagmiConfig config={wagmiConfig}>
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mint" element={<Mint />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </Layout>
  </WagmiConfig>
);
