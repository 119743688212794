import React, { LegacyRef } from 'react';
import clsx from 'clsx';

import styles from './Button.module.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary';
  size?: 'medium';
  disabled?: boolean | undefined;
}

export const Button = React.forwardRef(
  (
    {
      children,
      variant = 'primary',
      size = 'medium',
      disabled = false,
      className,
      ...props
    }: ButtonProps,
    ref,
  ) => {
    const variants = {
      primary: styles.primary,
      secondary: styles.secondary,
    };

    const sizes = {
      medium: styles.sizeMedium,
    };

    const classNames = clsx(
      styles.btn,
      variants[variant],
      sizes[size],
      className,
      {
        [styles.disabled]: disabled,
      },
    );

    return (
      <button
        type="button"
        disabled={disabled}
        className={classNames}
        ref={ref as LegacyRef<HTMLButtonElement>}
        {...props}
      >
        {children}
      </button>
    );
  },
);
