import { createConfig, configureChains } from 'wagmi';
import { mainnet } from 'wagmi/chains';

import { publicProvider } from 'wagmi/providers/public';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';

const { chains, publicClient } = configureChains([mainnet], [publicProvider()]);

export const metamaskConnector = new MetaMaskConnector({ chains });

// Set up wagmi config
export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [metamaskConnector],
  publicClient,
});
