import { appConfig } from 'config';
import { tokenInterceptor } from './tokenInterceptor';
import { ApiInterceptor } from './types';
import { ApiError } from './errors';

type RequestParams = {
  transformData?: (response: Response) => Promise<object | string>;
};

export class ApiClient {
  private readonly baseUrl: string;

  private interceptor!: ApiInterceptor | null;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setInterceptor(interceptor: ApiInterceptor) {
    this.interceptor = interceptor;
  }

  public async request(
    url: string,
    method: 'GET' | 'POST',
    data?: unknown,
    params?: RequestParams,
  ) {
    const isFormData = data instanceof FormData;

    const headers: HeadersInit = {};

    if (!isFormData) {
      headers['Content-Type'] = 'application/json';
    }

    let body = data;

    if (!isFormData) {
      body = data ? JSON.stringify(data) : undefined;
    }

    const request: RequestInit = {
      method,
      headers,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      body: body as BodyInit,
    };

    if (this.interceptor) {
      await this.interceptor(request);
    }

    const response = await fetch(`${this.baseUrl}${url}`, request);

    if (!response.ok) {
      const error: { message: string } = await response.json();

      console.log(response);

      if (error.message) {
        throw new ApiError(error.message, response.status);
      } else {
        throw new ApiError(await response.text(), response.status);
      }
    }

    if (params?.transformData) {
      return params.transformData(response);
    }

    return response.json();
  }

  async post<T>(
    url: string,
    data?: object | FormData,
    params?: RequestParams,
  ): Promise<T> {
    return this.request(url, 'POST', data, params);
  }

  async get<T>(url: string, params: RequestParams): Promise<T> {
    return this.request(url, 'GET', undefined, params);
  }
}

export const apiClient = new ApiClient(appConfig.apiUrl);

apiClient.setInterceptor(tokenInterceptor);
