import React, { useMemo } from 'react';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';

import { useNetwork } from 'wagmi';
import { appConfig } from 'config';
import styles from './Layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { chain } = useNetwork();

  const isShowWrongNetworkMessage = useMemo(
    () => chain?.id && appConfig.chainId !== chain.id,
    [chain?.id],
  );

  return (
    <>
      <Header className={styles.header} />
      <main className={styles.main}>
        {isShowWrongNetworkMessage && (
          <div className={styles.wrongNetworkMessage}>
            Your wallet is connected to the wrong network ({chain?.name}).
            Please make sure to switch to (Ethereum) network before you buy!
          </div>
        )}
        {children}
      </main>
      <Footer />
    </>
  );
};
