import React, { FormEvent, FormEventHandler, useRef } from 'react';
import { useAccount, useContractWrite, WagmiConfig } from 'wagmi';
import { appConfig } from 'config';
import { wagmiConfig } from 'config/wagmi';
import { keccak256 } from '@ethersproject/keccak256';
import { toUtf8Bytes } from '@ethersproject/strings';

import { Input } from 'components/Input';
import { ConnectWallet } from 'components/ConnectWallet';
import { Button } from 'components/Button';
import nftContractData from 'contracts/Collectible.json';

import styles from './Admin.module.scss';

export const Admin: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { address } = useAccount();

  const { writeAsync: buy } = useContractWrite({
    address: appConfig.nftContractAddress,
    abi: nftContractData.abi,
    functionName: 'buy',
    account: address,
  });

  const { writeAsync: grantRole } = useContractWrite({
    address: appConfig.nftContractAddress,
    abi: nftContractData.abi,
    functionName: 'grantRole',
    account: address,
  });

  const { writeAsync: setName } = useContractWrite({
    address: appConfig.nftContractAddress,
    abi: nftContractData.abi,
    functionName: 'setName',
    account: address,
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (
    e: FormEvent,
  ) => {
    e.preventDefault();

    const currentTarget = e.currentTarget as HTMLFormElement;
    const formData = new FormData(currentTarget);

    const minterRole = keccak256(toUtf8Bytes('MINTER_ROLE'));

    await grantRole({
      args: [minterRole, formData.get('address')],
    });
    formRef.current?.reset();
  };

  const handleBuy: FormEventHandler<HTMLFormElement> = async (e: FormEvent) => {
    e.preventDefault();

    const currentTarget = e.currentTarget as HTMLFormElement;
    const formData = new FormData(currentTarget);

    buy({
      args: [
        formData.get('tokenId') || appConfig.tokenId,
        formData.get('amount'),
        formData.get('receiver'),
      ],
    });
  };

  const handleChangeName = async (e: FormEvent) => {
    e.preventDefault();

    const currentTarget = e.currentTarget as HTMLFormElement;
    const formData = new FormData(currentTarget);

    setName({
      args: [formData.get('name')],
    });
  };

  return (
    <WagmiConfig config={wagmiConfig}>
      <div className={styles.root}>
        <div className={styles.header}>
          <ConnectWallet />
        </div>
        <form ref={formRef} className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.row}>
            <Input
              className={styles.input}
              label="Minter"
              name="address"
              variant="secondary"
            />
          </div>
          <div className={styles.row}>
            <Button className={styles.submit} variant="secondary" type="submit">
              Grant Minter Role
            </Button>
          </div>
        </form>
        <form ref={formRef} className={styles.form} onSubmit={handleBuy}>
          <div className={styles.row}>
            <Input
              className={styles.input}
              defaultValue={appConfig.tokenId}
              label="TokenId"
              name="tokenId"
              variant="secondary"
            />
          </div>
          <div className={styles.row}>
            <Input
              className={styles.input}
              label="Receiver"
              name="receiver"
              variant="secondary"
            />
          </div>
          <div className={styles.row}>
            <Input
              className={styles.input}
              label="Amount"
              name="amount"
              variant="secondary"
            />
          </div>
          <div className={styles.row}>
            <Button className={styles.submit} variant="secondary" type="submit">
              Send
            </Button>
          </div>
        </form>
        <form ref={formRef} className={styles.form} onSubmit={handleChangeName}>
          <div className={styles.row}>
            <Input
              className={styles.input}
              label="Name"
              name="name"
              variant="secondary"
            />
          </div>
          <div className={styles.row}>
            <Button className={styles.submit} variant="secondary" type="submit">
              Change name
            </Button>
          </div>
        </form>
      </div>
    </WagmiConfig>
  );
};
