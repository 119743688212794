import { apiClient } from './client';
import { ApiCredentials } from './client/types';

export const getNonce = async (address: string) =>
  apiClient.get<string>(`/auth/nonce?address=${address}`, {
    transformData: (res: Response) => res.text(),
  });

export const login = async (
  address: string,
  message: string,
  signature: string,
) =>
  apiClient.post('/auth/login', {
    address,
    message,
    signature,
  });

export const refreshToken = async (oldRefreshToken: string) =>
  apiClient.post<ApiCredentials>('/auth/refresh', {
    refreshToken: oldRefreshToken,
  });
