import React from 'react';
import { Button } from 'components/Button';
import { SubscriptionForm } from 'components/SubscriptionForm';
import {
  useAccount,
  useConnect,
  useContractRead,
  useContractWrite,
} from 'wagmi';
import { appConfig } from 'config';
import nftContractData from 'contracts/Collectible.json';
import { ContractFunctionExecutionError, parseEther } from 'viem';
import { useDialog } from 'hooks/use-dialog';

import styles from './Home.module.scss';

export const Home: React.FC = () => {
  const { address } = useAccount();
  const { connectAsync, connectors } = useConnect();
  const [dialogEl, showDialog] = useDialog();

  const { writeAsync: buy } = useContractWrite({
    address: appConfig.nftContractAddress,
    abi: nftContractData.abi,
    functionName: 'buy',
    account: address,
  });

  const { data: tokenPrice } = useContractRead({
    address: appConfig.nftContractAddress,
    abi: nftContractData.abi,
    functionName: 'getTokenPrice',
    args: [appConfig.tokenId],
  });

  const handleBuy = async () => {
    if (!address) {
      try {
        await connectAsync({
          connector: connectors[0],
          chainId: appConfig.chainId,
        });
      } catch (err) {
        console.error(err);
      }
    }

    try {
      await buy({
        args: [appConfig.tokenId],
        value:
          (tokenPrice as bigint) ||
          parseEther(appConfig.tokenPrice.toString() as `${number}`),
      });
    } catch (err) {
      if (!(err instanceof ContractFunctionExecutionError)) {
        showDialog({
          title: 'Error!',
          content: 'Something went wrong!',
        });

        return;
      }

      if (err.details.includes('insufficient funds')) {
        showDialog({
          title: 'Error!',
          content: 'Insufficient funds',
        });

        return;
      }

      showDialog({
        title: 'Error!',
        content: 'Something went wrong!',
      });
    }
  };

  return (
    <div className={styles.root}>
      {dialogEl}
      <div className={styles.content}>
        <div className={styles.innerContent}>
          <div className={styles.bg} />

          <div className={styles.leftBlock}>
            <p className={styles.title}>Serves You Right</p>
            <p className={styles.subtitle}>A novel by Yehudit Mam</p>
            <p className={styles.ctaText}>
              <a href="https://l5uo6dudetypn462sdywjhkcecm3tfabgukxjdjiqlocwdtgmywa.arweave.net/X2jvDoMk8Pbz2pDxZJ1CIJm5lAE1FXSNKILcKw5mZiw/">
                Read the novel
              </a>
            </p>
          </div>

          <div className={styles.rightBlock}>
            <p className={styles.editorial}>
              Editorial Ferragosto is launching an NFT bookstore.
            </p>
            <p className={styles.ctaText}>
              Collect our NFT books here or buy them on OpenSea.
            </p>
            <Button className={styles.ctaBtn} onClick={handleBuy}>
              Buy NFT
            </Button>
          </div>
        </div>
      </div>
      <SubscriptionForm className={styles.subscriptionForm} />
    </div>
  );
};
