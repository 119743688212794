import { CREDENTIALS_STORE_KEY } from './constants';
import { ApiInterceptor } from './types';
import { decodeAccessToken } from './utils';

export const tokenInterceptor: ApiInterceptor = async (
  request: RequestInit,
) => {
  const { accessToken, refreshToken } = JSON.parse(
    localStorage.getItem(CREDENTIALS_STORE_KEY) || '{}',
  );

  if (accessToken && refreshToken) {
    const decodedAccessToken = decodeAccessToken(accessToken);

    if (decodedAccessToken.exp * 1000 > Date.now()) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    } else {
      localStorage.removeItem(CREDENTIALS_STORE_KEY);

      try {
        const newCredentials = await refreshToken(refreshToken);

        localStorage.setItem(
          CREDENTIALS_STORE_KEY,
          JSON.stringify(newCredentials),
        );
      } catch (err) {
        console.warn((err as Error).message);
      }
    }
  }
};
