import { apiClient } from './client';
import { UploadMetadata } from './types';

export const uploadFile = async (file: File) => {
  const data = new FormData();

  data.append('file', file);

  return apiClient.post<{ hash: string; uri: string }>(`/arweave/upload`, data);
};

export const uploadMetadata = async (data: UploadMetadata) =>
  apiClient.post<{ hash: string; uri: string }>(
    `/arweave/upload-metadata`,
    data,
  );
