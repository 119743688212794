/* eslint-disable no-console */
import React, { useRef, useState } from 'react';
import { WagmiConfig, useAccount, useContractWrite } from 'wagmi';
import { wagmiConfig } from 'config/wagmi';

import * as api from 'api';

import nftContractData from 'contracts/Collectible.json';
import { UploadMetadata } from 'api/types';
import { parseEther } from '@ethersproject/units';
import { appConfig } from 'config';
import { useDialog } from 'hooks/use-dialog';
import { ConnectWallet } from 'components/ConnectWallet';
import { MintForm, MintFormValues } from './MintForm';

import styles from './Mint.module.scss';

export const Mint: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { address } = useAccount();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dialogEl, showDialog] = useDialog();

  const { writeAsync: mint } = useContractWrite({
    address: appConfig.nftContractAddress,
    abi: nftContractData.abi,
    functionName: 'mint',
    account: address,
  });

  const handleSubmit = async ({ nft, cover, ...rest }: MintFormValues) => {
    try {
      console.log('Start uploading conver and nft files');
      setIsSubmitting(true);

      const [coverResponse, nftResponse] = await Promise.all([
        api.uploadFile(cover),
        api.uploadFile(nft),
      ]);

      console.log(
        'End uploading conver and nft files: ',
        coverResponse,
        nftResponse,
      );

      console.log('Start uploading metadata');

      const metadataParams: UploadMetadata = {
        ...rest,
        externalUrl: nftResponse.uri,
        image: coverResponse.uri,
      };

      const metadataResponse = await api.uploadMetadata(metadataParams);

      console.log('End uploading metadata: ', metadataResponse);

      console.log('Start minting nft');

      const royaltyAddress: string[] = [];
      const royaltyShares: number[] = [];

      rest.royalty?.forEach((item) => {
        if (item.address) {
          royaltyAddress.push(item.address);
          royaltyShares.push(+item.shares * 100);
        }
      });

      await mint({
        args: [
          metadataResponse.hash,
          rest.editions,
          parseEther(rest.price),
          royaltyAddress,
          royaltyShares,
        ],
      });
      console.log('End minting nft');
      formRef.current?.reset();
    } catch (err) {
      showDialog({
        title: 'Error!',
        content: (err as Error).message,
      });
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <WagmiConfig config={wagmiConfig}>
      <div className={styles.root}>
        {dialogEl}
        <div className={styles.header}>
          <ConnectWallet />
        </div>
        <MintForm
          ref={formRef}
          className={styles.form}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
        />
      </div>
    </WagmiConfig>
  );
};
