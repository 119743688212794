import React, { AnchorHTMLAttributes } from 'react';
import cn from 'clsx';

import styles from './NavLink.module.scss';

interface NavLinkProps {
  className?: string;
  to: string;
  external?: boolean;
  children: React.ReactNode;
}

export const NavLink: React.FC<NavLinkProps> = ({
  className,
  to,
  external = false,
  children,
}) => {
  const anchorProps: Partial<AnchorHTMLAttributes<HTMLAnchorElement>> = {};

  if (external) {
    anchorProps.target = '_blank';
    anchorProps.rel = 'nore noreferrer';
  }

  return (
    <a className={cn(styles.root, className)} href={to} {...anchorProps}>
      {children}
    </a>
  );
};
