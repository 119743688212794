import React, { useCallback, useEffect } from 'react';

import {
  useAccount,
  useChainId,
  useConnect,
  useDisconnect,
  useSignMessage,
} from 'wagmi';
import { useDialog } from 'hooks/use-dialog';
import truncateEthAddress from 'utils';
import * as api from 'api';

import { SiweMessage } from 'siwe';
import { CREDENTIALS_STORE_KEY } from 'api/client/constants';
import { Button } from 'components/Button';

import styles from './ConnectWallet.module.scss';

export const ConnectWallet: React.FC = () => {
  const { address, isConnected } = useAccount();
  const { connect, connectors, isLoading } = useConnect();
  const { disconnect } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const chainId = useChainId();
  const [dialogEl, showDialog] = useDialog();

  const handleConnect = () => {
    connect({ connector: connectors[0] });
  };

  const signInWithEthereum = useCallback(
    async (_address: string, _chainId: number) => {
      try {
        const nonce = await api.getNonce(_address);

        const message = new SiweMessage({
          domain: window.location.host,
          address: _address,
          statement: 'Sign in with Ethereum to the Ferragosto app.',
          uri: window.location.origin,
          version: '1',
          chainId: _chainId,
          nonce,
        }).prepareMessage();

        const signature = await signMessageAsync({ message });
        const credentials = await api.login(_address, message, signature);

        localStorage.setItem(
          CREDENTIALS_STORE_KEY,
          JSON.stringify(credentials),
        );
      } catch (err) {
        if (err instanceof api.ApiError) {
          showDialog({
            title: 'Error!',
            content: err.message,
          });
          disconnect();

          console.error(err);
        }

        console.error(err);
      }
    },
    [disconnect, showDialog, signMessageAsync],
  );

  useEffect(() => {
    if (address && isConnected) {
      signInWithEthereum(address, chainId);
    }
  }, [address, chainId, isConnected, signInWithEthereum]);

  return (
    <div className={styles.root}>
      {dialogEl}
      {address && (
        <span className={styles.address}>{truncateEthAddress(address)}</span>
      )}
      {!address && (
        <Button onClick={handleConnect}>
          Connect Wallet {isLoading ? '(connecting...)' : ''}
        </Button>
      )}
    </div>
  );
};
