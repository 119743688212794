import React, { useCallback, useState } from 'react';
import { Dialog, DialogProps } from 'components/Dialog';

type UseDialogProps = Omit<DialogProps, 'isOpen'>;

export const useDialog = (
  initialProps?: Partial<UseDialogProps>,
): [React.ReactNode, (newProps: Partial<UseDialogProps>) => void] => {
  const [isOpen, setIsOpen] = useState(false);
  const [props, setProps] = useState<Partial<UseDialogProps>>(
    initialProps || {},
  );

  const handleShow = useCallback((newProps: Partial<UseDialogProps>) => {
    setProps(newProps);
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const dialogEl = (
    <Dialog
      {...(props as UseDialogProps)}
      isOpen={isOpen}
      onClose={handleClose}
    />
  );

  return [dialogEl, handleShow];
};
