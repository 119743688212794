import React, { FormEvent, Ref, useState } from 'react';
import cn from 'clsx';
import { Button } from 'components/Button';
import { Input } from 'components/Input';

import styles from './MintForm.module.scss';

export interface MintFormValues {
  name: string;
  description: string;
  year: number;
  month: number;
  editions: number;
  subject: string;
  language: string;
  author: string;
  contributors?: string;
  publisher: string;
  publisherUrl: string;
  context?: string;
  tags: string[];
  nft: File;
  cover: File;
  price: string;
  royalty?: Array<{ address: string; shares: number }>;
}

interface MintFormProps {
  className?: string;
  isSubmitting?: boolean;
  onSubmit: (values: MintFormValues) => void;
}

export const MintForm = React.forwardRef(
  (
    { className, isSubmitting = false, onSubmit }: MintFormProps,
    ref: Ref<HTMLFormElement>,
  ) => {
    const [rows, setRows] = useState(0);

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (
      e: FormEvent,
    ) => {
      e.preventDefault();

      const currentTarget = e.currentTarget as HTMLFormElement;
      const formData = new FormData(currentTarget);

      const rawValues = [...formData.entries()].reduce(
        (result, [key, value]) => {
          if (key.includes('royalty-')) {
            result.royalty = result.royalty || [];

            const subKey = key.split('-')[1];
            const index = key.replace(/[^0-9]/g, '');

            result.royalty[index] = result.royalty[index] || {};
            result.royalty[index][subKey] = value;
          }

          result[key] = value;

          return result;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {} as any,
      );

      const values: MintFormValues = {
        ...rawValues,
        editions: +rawValues.editions,
        year: +rawValues.year,
        month: +rawValues.month,
        tags: (rawValues.tags || '').split(','),
      };

      onSubmit(values);
    };

    const handleAddRow = () => {
      setRows((prev) => prev + 1);
    };

    const handleRemoveRow = () => {
      setRows((prev) => prev - 1);
    };

    return (
      <form
        ref={ref}
        className={cn(styles.root, className)}
        encType="multipart/form-data"
        onSubmit={handleSubmit}
      >
        <h4 className={styles.title}>Information & Files of the Publication</h4>
        <div className={styles.row}>
          <Input
            className={styles.input}
            variant="secondary"
            label="Name"
            name="name"
            placeholder="Enter Name"
            required
          />
          <Input
            className={styles.input}
            variant="secondary"
            label="Author"
            name="author"
            placeholder="Enter author"
            required
          />
        </div>
        <div className={styles.row}>
          <Input
            className={styles.input}
            variant="secondary"
            label="Publisher"
            name="publisher"
            placeholder="Enter publisher"
            required
          />
          <Input
            className={styles.input}
            variant="secondary"
            label="Publisher Url"
            name="publisherUrl"
            placeholder="Enter publisher url"
            required
          />
        </div>
        <div className={styles.row}>
          <Input
            className={styles.input}
            variant="secondary"
            label="Price"
            name="price"
            placeholder="Enter price"
            type="number"
            step="any"
            required
          />
          <Input
            className={styles.input}
            variant="secondary"
            label="Editions"
            name="editions"
            placeholder="Enter editions"
            type="number"
            required
          />
        </div>
        <div className={styles.row}>
          <Input
            className={styles.input}
            variant="secondary"
            label="Year"
            name="year"
            placeholder="Enter year"
            type="number"
            required
          />
          <Input
            className={styles.input}
            variant="secondary"
            label="Month"
            name="month"
            placeholder="Enter month"
            type="number"
            required
          />
        </div>
        <div className={styles.row}>
          <Input
            className={styles.full}
            variant="secondary"
            label="Description"
            name="description"
            placeholder="Enter description"
            required
          />
        </div>
        <div className={styles.row}>
          <Input
            className={styles.input}
            variant="secondary"
            label="Tags"
            name="tags"
            placeholder="Enter tags"
          />
          <Input
            className={styles.input}
            variant="secondary"
            label="Contributors"
            name="contributors"
            placeholder="Enter contributors"
          />
        </div>
        <div className={styles.row}>
          <Input
            className={styles.input}
            variant="secondary"
            label="language"
            name="language"
            placeholder="Enter language"
            required
          />
          <Input
            className={styles.input}
            variant="secondary"
            label="Subject"
            name="subject"
            placeholder="Enter subject"
            required
          />
        </div>
        <div className={styles.row}>
          <h4 className={styles.legend}>Royalty</h4>
        </div>
        <div className={styles.row}>
          <Input
            className={styles.input}
            label="Address"
            name="royalty-address-[0]"
            variant="secondary"
          />
          <Input
            className={styles.input}
            name="royalty-shares-[0]"
            label="%"
            variant="secondary"
            type="number"
            step="any"
          />
        </div>
        {Array(rows)
          .fill(0)
          .map((_, index) => (
            <div className={styles.row}>
              <Input
                className={styles.input}
                label="Address"
                name={`royalty-address-[${index + 1}]`}
                variant="secondary"
              />
              <Input
                className={styles.input}
                name={`royalty-shares-[${index + 1}]`}
                label="%"
                variant="secondary"
                type="number"
                step="any"
              />
            </div>
          ))}
        <div className={cn(styles.row, styles.actions)}>
          <button
            type="button"
            className={styles.rowBtn}
            onClick={handleRemoveRow}
          >
            remove row
          </button>
          <button
            type="button"
            className={styles.rowBtn}
            onClick={handleAddRow}
          >
            add row
          </button>
        </div>
        <div className={styles.row}>
          <h4 className={styles.legend}>Upload Files</h4>
        </div>
        <div className={styles.row}>
          <Input
            className={styles.input}
            label="Cover"
            name="cover"
            variant="secondary"
            type="file"
            required
          />
          <Input
            className={styles.input}
            label="Nft file"
            name="nft"
            variant="secondary"
            type="file"
            required
          />
        </div>
        <Button
          className={styles.submit}
          variant="secondary"
          disabled={isSubmitting}
          type="submit"
        >
          Mint {isSubmitting ? '...' : ''}
        </Button>
      </form>
    );
  },
);
