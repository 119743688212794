import React, { useState } from 'react';
import cn from 'clsx';
import { Input } from 'components/Input';
import { Button } from 'components/Button';

import { appConfig } from 'config';
import styles from './Subscription.module.scss';

interface SubscriptionFormProps {
  className?: string;
}

const stringifyQuery = (params: Record<string, string>) =>
  new URLSearchParams(params).toString();

export const SubscriptionForm: React.FC<SubscriptionFormProps> = ({
  className,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = async ({
    currentTarget,
  }: React.FormEvent<HTMLFormElement>) => {
    setError(null);

    const form = new FormData(currentTarget);
    const email = form.get('email');

    if (!email) {
      setError('Please enter a valid email address');

      return;
    }

    setIsSubmitting(true);

    const query = stringifyQuery({
      u: appConfig.mailchimpUserId,
      id: appConfig.mailchimpAudienceId,
      c: '?',
    });

    const url = `https://${appConfig.mailchimpAccount}.us${appConfig.mailchimpZoneId}.list-manage.com/subscribe/post-json?${query}`;
    const formData = new FormData();

    formData.append('EMAIL', email);
    formData.append('subscribe', 'Subscribe');

    try {
      await fetch(url, {
        method: 'POST',
        body: formData,
        mode: 'no-cors',
      });
    } catch (e) {
      setError('Failed to subscribe. Please try again.');
    } finally {
      const TIMEOUT_FOR_AUTOCLEAR = 3000;

      currentTarget.reset();
      setIsSubmitting(false);

      setTimeout(() => {
        setError(null);
      }, TIMEOUT_FOR_AUTOCLEAR);
    }
  };

  return (
    <form className={cn(styles.root, className)} onSubmit={onSubmit}>
      <h4 className={styles.title}>Stay in the loop!</h4>

      <div className={styles.wrap}>
        <Input
          className={styles.input}
          name="email"
          type="email"
          required
          placeholder="Enter email"
        />
        <Button type="submit" variant="secondary" disabled={isSubmitting}>
          Let’s do it!
        </Button>
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </form>
  );
};
