import React, { useEffect, useState } from 'react';
import cn from 'clsx';
import { ReactComponent as LogoSvg } from 'assets/images/logo.svg';
import { ReactComponent as IconMenu } from 'assets/icons/menu.svg';
import { ReactComponent as IconCross } from 'assets/icons/cross.svg';

import { NavLink } from 'components/NavLink';
import styles from './Header.module.scss';

interface HeaderProps {
  className?: string;
}

export const Header: React.FC<HeaderProps> = ({ className }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleToggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMobileMenuOpen]);

  return (
    <header className={cn(styles.root, className)}>
      <a href="/" className={styles.logoLink}>
        <LogoSvg className={styles.logo} />
      </a>
      <nav className={styles.nav}>
        <NavLink to="https://www.editorialferragosto.com" external>
          Editorial Ferragosto
        </NavLink>
        <NavLink to="https://twitter.com/EFerragosto" external>
          Twitter
        </NavLink>
      </nav>
      <button
        type="button"
        className={styles.mobileBtn}
        onClick={handleToggleMobileMenu}
      >
        <IconMenu />
      </button>
      <div
        className={cn(styles.mobileMenu, { [styles.open]: isMobileMenuOpen })}
      >
        <button
          className={styles.mobileMenuCloseBtn}
          onClick={handleToggleMobileMenu}
        >
          <IconCross />
        </button>
        <nav className={styles.mobileNav}>
          <NavLink to="https://www.editorialferragosto.com" external>
            Editorial Ferragosto
          </NavLink>
          <NavLink to="https://twitter.com/EFerragosto" external>
            Twitter
          </NavLink>
        </nav>
      </div>
    </header>
  );
};
